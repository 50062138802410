<template>
    <div>
       
        <div class="row">
            <div class="col-lg-6">
                <div class="row mb-10">
                    <div class="col-lg-12 fv-row">
                        <label class="col-form-label">Sözleşme Adı</label>                            
                        <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedItem.contractName" />
                    </div>
                </div>

                <div class="row mb-10">
                    <div class="col-lg-12 fv-row">
                        <label class="col-form-label">Sözleşme Tipi</label>                            
                        <select class="form-control form-select" name="contractTypeId" v-model="selectedItem.contractTypeId" id="contractTypeId" :disabled="mode=='edit'">
                            <option value="">Seçiniz</option>
                            <option :value="item.contractTypeId" :key="item.contractTypeId" v-for="item in contractTypes">{{item.contractType}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="col-lg-6" :style="mode=='edit' ? 'opacity:.5': ''">
                <label class="col-form-label">Geçerli olduğu ülkeler</label>
                <div class="d-flex flex-wrap">
                    <div class="col-lg-4 mb-10" v-for="country in countries" :key="country.numCode">
                        <label>
                            <input type="checkbox" name="countryIsoList[]" :value="country.iso" v-model="selectedItem.countries" :disabled="mode=='edit'">
                            {{country.name}}
                        </label>
                    </div>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name:'EditPackage',
    props:['selectedItem','mode','contractTypes','packages','countries'],
}
</script>