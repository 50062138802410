<template>
  <div class="card mb-5 mb-xl-12">
    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
      
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Sözleşmeler</h3>

        
      </div>      
    </div>
    
    
    <div id="kt_account_profile_details" class="collapse show" v-if="items">

        <div id="package-detail" v-if="showDetail">
            <div class="card-body border-top p-9">                
                <EditContract :selectedItem="selectedItem" :mode="mode" v-if="contractTypes" :countries="countries" :contractTypes="contractTypes" />
            </div>

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button type="reset" @click.prevent="cancelForm()" class="btn btn-white btn-active-light-primary me-2">Vazgeç</button>

                <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" @click.prevent="update()" class="btn btn-primary">
                    <span class="indicator-label"> Kaydet </span>
                    <span class="indicator-progress">
                    Lütfen bekleyin...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
            <!--end::Actions-->
        </div>


        <div class="card-body border-top p-9" v-show="!showDetail">
            <div class="table-responsive">

              <div class="d-flex justify-content-between align-items-start">
                <div>
                  Sözleşme Türü
                <select name="" style="width:auto" class="form-select" id="" v-model="selectedContractType" @change="getList()">
                  <option value="0">Tümü</option>
                  <option :value="contractType.contractTypeId" v-for="contractType in contractTypes" :key="contractType.contractTypeId">{{contractType.contractType}}</option>
                </select>
                </div>

                <a href="" @click.prevent="add()" class="btn btn-primary float-right">Yeni Sözleşme Ekle</a>
                </div>
                <hr>
              <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Sözleşme Adı</th>
                    <th>Sözleşme Tipi	</th>
                    <th>Ülke Kodları</th>
                    <th>Durum</th>                    
                    <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                    <td>{{item.contractName}}</td>
                    <td>{{item.contractType}}</td>
                    <td>{{item.countries}}</td>
                    <td><span :class="'badge badge-light-'+statusses.class[item.status]">{{statusses.label[item.status]}}</span></td>                    
                    <td><ActionsMenu :item="item" :packagekey="i" @versions="versions($event)" @edit="edit($event)" /></td>
                    </tr>            
                </tbody>
              </table>
      
            </div>
        </div>
    </div>
    
        
    
  </div>


</template>



<script>
import api from '@/core/services/ApiService'
import ActionsMenu from "@/components/dropdown/ContractsListActions.vue";
import EditContract from '@/components/modals/forms/EditContract.vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";

const newItemValues={
    contractName:null,
    contractTypeId:"",
    countryIsoList:[],
    countries:[],
    status:'AC'
}



export default  {
  name:'PackageGroups',
  components:{
      ActionsMenu,
      EditContract,      
  },
  data(){
    
    return{       
        selectedContractType:0, 
        selectedItem:newItemValues,
        mode:'new',

      showDetail:false,
      
      statusses : {
        class:{
          'AC':'success',
          'DA':'danger'
        },
        label:{
          'AC':'Aktif',
          'DA':'Pasif'
        }
      },       
      items:null,
      countries:null,
      contractTypes:null,
      

    }
  },

  

  methods:{

      versions(item){
        
        this.$router.push({name:'contract-versions',params:{contractName:item.contractName},query:{contractId:item.contractId}});
      },
      edit(item){
          this.showDetail=true;
          this.mode='edit';
          this.selectedItem = item;
      },

      cancelForm(){
          this.showDetail=false;
      },

      add(){
          this.mode='new';
          this.selectedItem = newItemValues;        
          this.showDetail = true;

      },

      getList(){
          localStorage.selectedContractType = this.selectedContractType;
          api.get('/User/ListUserContracts/JJ/'+this.selectedContractType).then((res)=>{
            this.items = res.data;
            
          })
      },

      

      update(){
          let apiAddress = this.mode=='new' ? '/User/NewUserContract' : '/User/UpdateUserContract';
          this.selectedItem.countryIsoList = this.selectedItem.countries;

          api.post(apiAddress,this.selectedItem).then((res)=>{
              Swal.fire({
                  text:'Sözleşme güncellendi',
                  icon:'success',
              }).then(()=>{
                  window.location.reload();
              });
          }).catch((err)=>{
              
              Swal.fire({
                  title:'Hata',
                  icon:'error',
                  text:err.response.data.message
              })
          })
      }
  },


  mounted(){

     setCurrentPageBreadcrumbs("Sözleşme Tipleri", ["Ayarlar","Sözleşmeler"]);
   //  this.selectedContractType = localStorage.selectedContractType ? localStorage.selectedContractType : 0;
     api.get('/User/ListCountry').then((res)=>{
        this.countries = res.data
    });

     api.get('/User/ListUserContractTypes').then((res)=>{
          this.contractTypes = res.data;
      });
      
      this.getList();
   
  },

}

</script>
<style scoped>
input:disabled{cursor: not-allowed; background-color: #eee;}
</style>