<template>
<div>
    <div class="dropdown">
    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="packageGroupListActions" ref="packageGroupListActions" data-bs-toggle="dropdown" aria-expanded="false">
        İşlemler
    </button>
    <ul class="dropdown-menu" aria-labelledby="packageGroupListActions">
        <li><a @click.prevent="versions(item)" class="dropdown-item">Versiyonlar</a></li>
        <li>
            <hr class="dropdown-divider">
        </li>
        <li><a class="dropdown-item" @click.prevent="edit(item)">Düzenle</a></li>
        
        </ul>
    </div>

      
    

</div>
</template>

<script>

export default {
    name:'FaqListActions',
    props:['item','packagekey'],

    methods:{
        versions(item){
            this.$emit('versions',item);
        },
        remove(item){
            this.$emit('remove',item);
        },
        edit(item){
            this.$emit('edit',item);
        },
        change(item){
            this.$emit('change',item);
        }
    },
    
   
}
</script>